<ng-template #template>
  <ng-content></ng-content>
</ng-template>
<ion-item class="list-item transparent-bg settings-item item-noborder">
  <ion-label class="text-color-primary label-text-m ion-label-input">{{label}}</ion-label>
  <ion-select class="custom-select custom-select-stretch info-text-m" ngDefaultControl [(ngModel)]="_prop"
    [placeholder]="placeholder" [disabled]="_disabled" (ionChange)="onChange()">
    <ion-select-option *ngFor="let o of options; let i = index" [value]="o.value">
      {{o.name}}
    </ion-select-option>
    <!-- <ion-select-option *ngIf="hasNullOption" [value]="null">undefined</ion-select-option> -->
  </ion-select>
</ion-item>