import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { AppConstants } from 'src/app/classes/app/constants';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';

export interface IUnlockContentCheckoutParams {
  description: string;
  descriptionFooter?: string;
  iconDescription?: string;
  iconFooter?: string;
  title: string;
  continueBtnText?: string;
  unlockCodes: string[];
}

export interface IUnlockContentCheckoutReturnData {
  actionCode: number;
  unlockCodes: string[];
  isUnlockCode: boolean;
}

export interface IUnlockCodeDisp {
  code: string;
  isQR: boolean;
}

@Component({
  selector: 'unlock-content-checkout',
  templateUrl: './unlock-content-checkout.component.html',
  styleUrls: ['./unlock-content-checkout.component.scss']
})
export class UnlockContentCheckoutComponent implements OnInit {
  description: SafeHtml = "";
  descriptionFooter: SafeHtml = "";
  title: string = "";
  iconFooter: string = "";
  iconDescription: string = "";

  wrapperClass: string = "";
  innerClass: string = "";
  buttonBar: string = "";

  loading: boolean = false;

  photoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();

  params: IUnlockContentCheckoutParams = null;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  continueBtnText: string = "Noted";
  unlockCodes: IUnlockCodeDisp[] = [];
  unlockCodesRaw: string[] = [];

  isUnlockCode: boolean = false;
  unlockCodeShortLength: number = 5;
  proceedHighlight: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IUnlockContentCheckoutParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.unlockCodeShortLength = AppConstants.gameConfig.unlockCodeLength;

      if (params.description) {
        this.description = this.sanitizer.bypassSecurityTrustHtml(params.description);
      }

      if (params.descriptionFooter) {
        this.descriptionFooter = this.sanitizer.bypassSecurityTrustHtml(params.descriptionFooter);
      }

      this.title = params.title;
      this.unlockCodesRaw = params.unlockCodes;
      this.iconFooter = params.iconFooter;
      this.iconDescription = params.iconDescription;
      if (params.continueBtnText != null) {
        this.continueBtnText = params.continueBtnText;
      }
      this.checkCode();
    }

    this.getClass();
  }

  checkCode() {
    if (this.unlockCodesRaw != null) {
      this.unlockCodes = this.unlockCodesRaw.map(ucr => {
        let isQR: boolean = ucr.length != this.unlockCodeShortLength;
        let uc: IUnlockCodeDisp = {
          isQR: isQR,
          code: ucr
        };
        return uc;
      });
    }
  }

  getClass() {
    this.wrapperClass = "";
    this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-border";
    this.buttonBar = "theme-border-top";
  }

  dismiss(params: IUnlockContentCheckoutReturnData) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    let params: IUnlockContentCheckoutReturnData = {
      actionCode: EAlertButtonCodes.ok,
      unlockCodes: this.unlockCodesRaw,
      isUnlockCode: this.isUnlockCode
    }
    this.dismiss(params);
  }

  cancel() {
    this.dismiss(null);
  }

}
