import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEditorOpt } from 'src/app/classes/def/core/editor';
import { InnerContentDirective } from 'src/app/directives/directives/inner-content';

@Component({
  selector: 'edit-options',
  templateUrl: './edit-options.component.html',
  styleUrls: ['./edit-options.component.scss'],
})
export class EditOptionsComponent implements OnInit, AfterContentInit {
  @ContentChild(InnerContentDirective, { read: ElementRef })
  content: ElementRef;

  _prop: any;
  @Input()
  set prop(prop: any) {
    this._prop = prop;
    // setTimeout(() => {
    //   this.propChange.emit(this._prop);
    // }, 1);
  }
  get prop() {
    return this._prop;
  }
  @Output()
  propChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  label: string;

  _disabled: boolean;
  @Input()
  set disabled(disabled: any) {
    this._disabled = disabled;
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  placeholder: string;

  @Input()
  options: IEditorOpt[];

  @Output()
  optionChange: EventEmitter<any> = new EventEmitter();

  hasNullOption: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  ngAfterContentInit() {
    if (!this.label) {
      if (this.content) {
        this.label = this.content.nativeElement.innerText;
      }
    }
  }

  init() {
    if (this.options) {
      for (let option of this.options) {
        if (option.value == null) {
          this.hasNullOption = true;
        }
      }
    }
  }

  onChange() {
    setTimeout(() => {
      // this actually does the trick    
      this.propChange.emit(this._prop);
      setTimeout(() => {
        this.optionChange.emit(this._prop);
      }, 1);
    }, 1);
  }

}
