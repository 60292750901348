<ion-header #header>
  <leplace-toolbar *ngIf="loadedPage" [menu]="false" [title]="title"
    [leftButton]="(isModal || fromMarket) ? appIconsStandard.back : appIconsStandard.home"
    [leftButtonDisabled]="linkPreview" [leftIcon]="true" (leftClick)="back()" [rightButton]="appIconsStandard.settings"
    [rightIcon]="true" (rightClick)="presentPopover()" [loading]="false">
  </leplace-toolbar>
</ion-header>

<!-- overflow-scroll="true" -->

<ion-content [ngClass]="theme" #content>
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame view-frame-border">
    <!-- bg-filter-1 -->
    <div class="bg-container bg-container-fill" [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}">

      <div class="content">

        <div *ngIf="!loaded" class="progress-spinner-center">
          <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </div>

        <div *ngIf="loaded" fxLayout="column" class="content-height no-scroll">

          <div class="scrollbar-y custom-scrollbar content-height" fxLayout="column" (scroll)='onContentScroll($event)'>
            <div *ngIf="storyLoaded" class="text-color-overlay margin-horizontal-s margin-top-s margin-bottom-s">

              <ion-row class="padding-xs">
                <ion-col size="12" class="text-center">
                  <span class="heading text-color-primary">{{story.name}}</span>
                </ion-col>
              </ion-row>

              <story-info-spec *ngIf="storyExt" [data]="storyExt"></story-info-spec>

              <ion-row class="padding-xs" *ngIf="storyExt && storyExt.modeText">
                <ion-col size="12" class="text-center">
                  <span class="info-text-s text-color-primary">{{storyExt.modeText}}</span>
                </ion-col>
              </ion-row>

              <ion-row class="padding-xs">
                <ion-col size="12" class="text-center">
                  <span class="info-text-m text-color-primary">{{levelDisp}}</span>
                </ion-col>
              </ion-row>

              <div tappable class="padding-xs text-center" *ngIf="isBeta">
                <span class="as-text element-selector info-text-s text-color-primary">UNLISTED</span>
              </div>

              <div tappable class="padding-xs text-center" *ngIf="isDev">
                <span class="as-text element-selector info-text-s text-color-primary">DEV</span>
              </div>

              <ion-row *ngIf="story.regionType">
                <ion-col size="12">
                  <div>
                    <span class="info-text-s margin-top-s bold text-color-element">{{story.regionType}}</span>
                  </div>
                </ion-col>
              </ion-row>

              <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="description"
                innerClass="info-text-m description-frame text-color-primary theme-border-top text-left padding-horizontal-s"></safe-html-renderer>

              <div class="margin-top-m">
                <a class="info-text-m bold text-color-primary no-underline element-selector"
                  (click)="scrollTo('sectionActivate')">Available in
                  Leplace World app</a>
              </div>

              <div class="text-color-overlay-flush margin-horizontal-s margin-top-s margin-bottom-s"
                *ngIf="hasProviders">
                <ion-list class="list-padding">
                  <span class="info-text-mlg bold text-color-primary padding-horizontal-s">Provided by</span>
                  <ion-item *ngFor="let bloc of storyProviders; let i = index;" class="list-item transparent-bg"
                    no-lines>
                    <place-items-card class="full-w" *ngIf="bloc" [location]="bloc" tappable
                      (click)="getPlaceDetails(i)">
                    </place-items-card>
                  </ion-item>
                </ion-list>
              </div>

              <ion-row *ngIf="storyPhotos">
                <ion-col class="padding-s center-text">
                  <span class="center-text info-text-mlg bold text-color-primary">In-Game Preview</span>
                </ion-col>
              </ion-row>

              <div class="bootstrap-carousel margin-top-s carousel-center-size" *ngIf="storyPhotos">
                <ngb-carousel>
                  <ng-template *ngFor="let sp of storyPhotos; let i = index" ngbSlide>
                    <div class="picsum-img-wrapper center-text ngb-slider-img-padding">
                      <img [src]="sp.photoUrl" class="slide-image-inner-modal">
                    </div>
                    <div class="carousel-caption">
                      <span class="center-text info-text-m bold text-color-primary">{{sp.name}}</span>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>


              <div id="sectionHowItWorks">
                <ion-row class="theme-border-heading">
                  <ion-col class="padding-s center-text">
                    <span class="center-text info-text-lg bold text-color-primary">How it works</span>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="infoText.howItWorks" class="center-items margin-top-s margin-bottom-s">
                  <ion-col size="2">
                    <img [src]="infoText.howItWorksIcon" class="img-xxs" />
                  </ion-col>
                  <ion-col size="10">
                    <div [innerHTML]="infoText.howItWorks"
                      class="info-text-m description-frame text-color-primary text-left">
                    </div>
                  </ion-col>
                </ion-row>

                <!-- <div *ngIf="tutorialVideoUrl" class="margin-top-xs margin-bottom-xs">
                  <youtube-view [videoUrl]="tutorialVideoUrl" customClass="video-portrait-mobile"></youtube-view>
                </div> -->

                <!-- <div *ngIf="tutorialVideoUrl" class="margin-top-s margin-bottom-xs">
                  <youtube-view [videoUrl]="tutorialVideoUrl" customClass="video-landscape"></youtube-view>
                </div> -->

                <div *ngIf="!isModal && tutorialVideoUrl" class="margin-top-s margin-bottom-xs">
                  <youtube-view [videoUrl]="tutorialVideoUrl" [portrait]="true"
                    customClass="video-portrait-mobile"></youtube-view>
                </div>

                <ion-row>
                  <ion-col size="12">
                    <ion-button class="as-text element-selector height-auto" (click)="toggleActivationGuide()">
                      <span class="button-font-size-s">View Activation Guide</span>
                      <icon-wrapper slot="start" [icon]="appIconsStandard.clipboard" class="slot-icon-padding">
                      </icon-wrapper>
                    </ion-button>
                  </ion-col>
                </ion-row>

                <div *ngIf="showActivationGuide">
                  <ion-row *ngIf="tutorialEmbed">
                    <ion-col size="12">
                      <div [innerHTML]="tutorialEmbed"
                        class="info-text-m description-frame text-color-primary padding-top-s">
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="tutorialEmbedPDFLink">
                    <ion-col size="12">
                      <!-- <iframe [src]="tutorialEmbedPDFLink" class="view-frame-pdf item-border-radius">
                      </iframe> -->
                      <object [data]="tutorialEmbedPDFLink" type="application/pdf"
                        class="view-frame-pdf item-border-radius">
                        <iframe
                          [src]="'https://docs.google.com/viewer?url=' + tutorialEmbedPDFLink + '&embedded=true'"></iframe>
                      </object>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="tutorialPreviewLink">
                    <ion-col size="12">
                      <img [src]="tutorialPreviewLink" class="full-w item-border-radius" style="max-width: 600px;">
                    </ion-col>
                  </ion-row>

                  <!-- <ion-row>
                  <ion-col size="12">
                    <ion-button class="as-text element-selector height-auto" (click)="downloadTutorial()">
                      <span class="button-font-size-s">Download PDF</span>
                      <icon-wrapper slot="start" [icon]="appIconsStandard.download" class="slot-icon-padding">
                      </icon-wrapper>
                    </ion-button>
                  </ion-col>
                </ion-row> -->

                </div>
              </div>

              <div id="sectionActivateCheckout">
                <ion-row class="theme-border-heading">
                  <ion-col class="padding-s center-text">
                    <span class="center-text info-text-lg bold text-color-primary">Activate / Checkout</span>
                  </ion-col>
                </ion-row>

                <div class="padding-s info-text-m description-frame text-color-primary text-left"
                  [innerHTML]="infoText.checkoutNotes">
                </div>

                <div *ngIf="noCheckout">
                  <ion-button class="action-button-fill button-color-alternate" (click)="toggleCheckout()">
                    <span class="button-font-size-s bold">{{buyMoreLabel}}</span>
                  </ion-button>
                </div>

                <iap-card *ngIf="story.itemIap && !noCheckout" [content]="story.itemIap"
                  [promo]="story.itemIapPromo"></iap-card>

                <stripe-card *ngIf="enableCheckout && !noCheckout" [price]="alternativePrice"
                  [promo]="alternativePricePromo" (checkout)="checkoutStripe()"></stripe-card>

                <ion-row *ngIf="hasStoryProducts && !noCheckout">
                  <ion-col class="padding-s center-text">
                    <span class="center-text info-text-mlg bold text-color-primary">Also available on</span>
                  </ion-col>
                </ion-row>

                <div class="bootstrap-carousel margin-top-s" *ngIf="hasStoryProducts && !noCheckout">
                  <ngb-carousel id="carousel" [interval]="0" [animation]="false"
                    (slid)="onProviderSlideSelected($event)">
                    <ng-template *ngFor="let product of storyProducts; let i = index" ngbSlide>
                      <div class="picsum-img-wrapper center-text ngb-slider-img-padding-large">
                        <div class="carousel-overlay-description">
                          <div *ngIf="product.title" [innerHTML]="product.title"
                            class="info-text-s text-color-overlay-s">
                          </div>
                        </div>
                        <img *ngIf="product.providerOta" [src]="product.providerOta.photoUrl"
                          class="slide-image-inner-modal slide-image-inner-modal-provider">
                      </div>
                      <div class="carousel-caption">
                        <ion-button *ngIf="product.providerOta"
                          class="as-text element-selector info-text-s text-color-primary text-transform-none"
                          (click)="openLink(product.link)">
                          <span class="button-font-size-s">View on {{product.providerOta.name}}</span>
                        </ion-button>
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>

                <div class="padding-s info-text-m description-frame text-color-primary text-left"
                  [innerHTML]="infoText.findStoryNotes">
                </div>

                <div *ngIf="!unlockCodes">
                  <ion-row>
                    <ion-col class="padding-s text-left">
                      <span class="text-left info-text-m bold text-color-primary">Sample unlock code for illustration
                        purpose only:</span>
                    </ion-col>
                  </ion-row>
                  <div>
                    <edit-code [(prop)]="defaultUnlock" placeholder="N/A" [numeric]="false" [dim]="defaultUnlock.length"
                      [autocaps]="false" [disabled]="true">
                      <inner-content></inner-content>
                    </edit-code>
                  </div>
                </div>

                <div *ngIf="unlockCodes">
                  <ion-row>
                    <ion-col class="padding-s text-left">
                      <span class="text-left info-text-m bold text-color-primary">Unlock codes:</span>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngFor="let uc of unlockCodes">
                    <ion-col size="12">
                      <div class="border-frame">
                        <edit-code [disabled]="true" [prop]="uc" placeholder="" [numeric]="false"
                          [dim]="unlockCodeShortLength" [autocaps]="true">
                        </edit-code>
                      </div>
                    </ion-col>
                  </ion-row>
                </div>

                <div class="padding-s info-text-m description-frame text-color-primary text-left"
                  [innerHTML]="infoText.unlockNotes">
                </div>

                <div class="text-color-overlay-flush margin-horizontal-s margin-top-s margin-bottom-s"
                  *ngIf="hasActivationCodes">

                  <ion-row>
                    <ion-col class="padding-s center-text">
                      <span class="center-text info-text-mlg bold text-color-primary">Input Codes</span>
                    </ion-col>
                  </ion-row>

                  <div *ngFor="let code of activationCodesList">
                    <div class="border-frame">
                      <edit-code [(prop)]="code.code" placeholder="N/A" [numeric]="false" [dim]="code.length"
                        [autocaps]="false" [disabled]="true">
                        <inner-content>{{code.name}}</inner-content>
                      </edit-code>
                      <ion-button class="as-text element-selector info-text-s text-color-primary"
                        (click)="copyToClipboard(code.code)">
                        <icon-wrapper slot="icon-only" [icon]="appIconsStandard.clipboard"
                          [custom]="false"></icon-wrapper>
                      </ion-button>
                    </div>
                  </div>

                  <div *ngIf="(hasActivationQRCodes && !partnerUnlock) || hasRedeemQRCodes"
                    class="theme-border-top margin-vertical-s">
                    <ion-row class="margin-top-s">
                      <ion-col class="padding-s center-text">
                        <span class="center-text info-text-mlg bold text-color-primary">QR Codes</span>
                      </ion-col>
                    </ion-row>
                    <ngb-carousel class="margin-top-s">
                      <ng-template *ngFor="let code of activationCodesQRList; let i = index" ngbSlide>
                        <div class="picsum-img-wrapper ngb-slider-img-padding">
                          <qr-code [index]="i" [code]="code.code" [interact]="false">
                          </qr-code>
                        </div>
                        <div class="carousel-caption">
                          <span class="center-text info-text-m bold text-color-primary">{{code.name}}</span>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                  </div>
                  <div *ngIf="partnerUnlock">
                    <ion-row>
                      <ion-col class="padding-s center-text">
                        <span class="center-text info-text-mlg bold text-color-primary">Unlock codes available from your
                          local provider</span>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>
              </div>

              <div id="sectionActivate">
                <ion-row class="theme-border-heading">
                  <ion-col class="padding-s center-text">
                    <span class="center-text info-text-lg bold text-color-primary">Get Leplace World</span>
                  </ion-col>
                </ion-row>

                <div class="padding-s info-text-m description-frame text-color-primary text-left"
                  [innerHTML]="infoText.appNotes">
                </div>

                <div class="text-color-overlay-flush margin-horizontal-s margin-top-s margin-bottom-s">
                  <div>
                    <ion-row>
                      <ion-col class="padding-s center-text">
                        <span class="center-text info-text-m bold text-color-primary">Public access (limited
                          distribution)</span>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="12" size-md="6" class="flex-column">
                        <ion-button class="as-text element-selector height-auto" [disabled]="!isPlayStore()"
                          (click)="goToPlayStore()">
                          <img [src]="imgIcons.playStore" class="img-xs" />
                        </ion-button>
                        <div *ngIf="!isPlayStore()" class="info-text-m description-frame text-color-primary text-left"
                          [innerHTML]="infoText.playStoreNotes"></div>
                        <!-- <span *ngIf="!dist.android"
                          class="center-text info-text-m bold text-color-primary">(unavailable)</span> -->
                      </ion-col>
                      <ion-col size="12" size-md="6" class="flex-column">
                        <ion-button class="as-text element-selector height-auto" [disabled]="!isAppStore()"
                          (click)="goToAppStore()">
                          <img [src]="imgIcons.appStore" class="img-xs" />
                        </ion-button>
                        <div *ngIf="!isAppStore()" class="info-text-m description-frame text-color-primary text-left"
                          [innerHTML]="infoText.appStoreNotes"></div>
                        <!-- <span *ngIf="!dist.ios || isTestFlightOnly"
                          class="center-text info-text-m bold text-color-primary">(unavailable)</span> -->
                      </ion-col>
                    </ion-row>
                  </div>

                  <div class="theme-border-top margin-vertical-s">
                    <ion-row>
                      <ion-col class="padding-s center-text">
                        <span class="center-text info-text-m bold text-color-primary">Early access (worldwide)</span>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="12" size-md="6">
                        <ion-button class="as-text element-selector height-auto" (click)="goToAppleTestFlight()">
                          <img [src]="imgIcons.testFlight" class="img-xs" />
                        </ion-button>
                      </ion-col>
                      <ion-col size="12" size-md="6">
                        <div class="info-text-m description-frame text-color-primary text-left"
                          [innerHTML]="infoText.testFlightNotes"></div>
                      </ion-col>
                    </ion-row>
                  </div>

                  <div class="theme-border-top margin-vertical-s" *ngIf="enableWeb">
                    <ion-row>
                      <ion-col class="padding-s center-text">
                        <span class="center-text info-text-m bold text-color-primary">Web (worldwide)</span>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="12" size-md="6">
                        <ion-button class="as-text element-selector height-auto" (click)="goToWeb()">
                          <img [src]="imgIcons.web" class="img-xs" />
                        </ion-button>
                      </ion-col>
                      <ion-col size="12" size-md="6">
                        <div class="info-text-m description-frame text-color-primary text-left"
                          [innerHTML]="infoText.webNotes">
                        </div>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="12">
                        <span class="center-text info-text-m bold text-color-primary">{{webUrl}}</span>
                      </ion-col>
                    </ion-row>
                  </div>

                  <!-- <ion-row class="padding-vertical-none">
                  <ion-col size="6" class="padding-vertical-none">
                    <span class="info-text-s">Play Store</span>
                  </ion-col>
                  <ion-col size="6" class="padding-vertical-none">
                    <span class="info-text-s">Apple Test Flight</span>
                  </ion-col>
                </ion-row> -->

                  <ion-row>
                    <ion-col size="12">
                      <ion-button class="as-text element-selector height-auto text-transform-none"
                        (click)="openWebpage()">
                        <span class="button-font-size-s">www.leplace.online</span>
                        <icon-wrapper slot="start" icon="globe" class="slot-icon-padding">
                        </icon-wrapper>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </div>
              </div>

              <!-- text-color-overlay-flush  -->

              <div id="sectionContact" class="margin-horizontal-s margin-top-m margin-bottom-s" *ngIf="extDataLoaded">
                <ion-row class="theme-border-heading">
                  <ion-col class="padding-s center-text">
                    <span class="center-text info-text-lg bold text-color-primary">Notes</span>
                  </ion-col>
                </ion-row>
                <div class="text-left">
                  <div *ngFor="let fc of faqCategories" class="list-item list-item-padding transparent-bg">
                    <div *ngFor="let e of fc.faqEntries" class="list-item list-item-padding transparent-bg">
                      <plain-entry-expand [e]="e" [fixed]="true"></plain-entry-expand>
                    </div>
                  </div>
                </div>
              </div>

              <div id="sectionContact">
                <ion-row class="theme-border-heading">
                  <ion-col class="padding-s center-text">
                    <span class="center-text info-text-lg bold text-color-primary">Contact</span>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="infoText.reviewContact" class="center-items">
                  <ion-col size="2">
                    <img [src]="infoText.reviewContactIcon" class="img-xxs" />
                  </ion-col>
                  <ion-col size="10">
                    <div [innerHTML]="infoText.reviewContact"
                      class="info-text-m description-frame text-color-primary text-left">
                    </div>
                  </ion-col>
                </ion-row>

                <div>
                  <!-- <ion-grid class="full-w">
                    <ion-row>
                      <ion-col size="6">
                        <ion-button class="action-button-fill button-color-primary" (click)="openFBPage()">
                          <icon-wrapper slot="icon-only" icon="logo-facebook"></icon-wrapper>
                        </ion-button>
                      </ion-col>
                      <ion-col size="6">
                        <ion-button class="action-button-fill button-color-primary" (click)="contact()">
                          <icon-wrapper slot="icon-only" icon="mail"></icon-wrapper>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid> -->

                  <ion-grid class="full-w">
                    <ion-row>
                      <ion-col size="6">
                        <ion-button class="as-text full-w element-selector height-auto text-transform-none"
                          (click)="openWebpage()">
                          <span class="button-font-size-s">WEB</span>
                          <icon-wrapper slot="start" icon="globe" class="slot-icon-padding">
                          </icon-wrapper>
                        </ion-button>
                      </ion-col>
                      <ion-col size="6">
                        <ion-button class="as-text full-w element-selector height-auto text-transform-none"
                          (click)="contact()">
                          <span class="button-font-size-s">EMAIL</span>
                          <icon-wrapper slot="start" icon="mail" class="slot-icon-padding">
                          </icon-wrapper>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </div>

              <div class="theme-border-heading"></div>

              <ion-row>
                <ion-col size="2">
                  <ion-button class="as-text full-w element-selector height-auto text-transform-none"
                    (click)="openFBPage()">
                    <!-- <span class="button-font-size-s">Facebook</span> -->
                    <icon-wrapper slot="icon-only" icon="logo-facebook">
                    </icon-wrapper>
                  </ion-button>
                </ion-col>
                <ion-col size="8">
                  <ion-button class="as-text full-w element-selector height-auto text-transform-none"
                    (click)="openWebpage()">
                    <icon-wrapper slot="icon-only" icon="globe">
                    </icon-wrapper>
                  </ion-button>
                </ion-col>
                <ion-col size="2">
                  <ion-button class="as-text full-w element-selector height-auto text-transform-none"
                    (click)="contact()">
                    <!-- <span class="button-font-size-s">Email</span> -->
                    <icon-wrapper slot="icon-only" icon="mail">
                    </icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>


              <ion-row>
                <ion-col size="12">
                  <ion-button class="as-text element-selector height-auto text-transform-none" (click)="openWebpage()">
                    <span class="button-font-size-s">www.leplace.online</span>
                    <!-- <icon-wrapper slot="start" icon="globe" class="slot-icon-padding">
                    </icon-wrapper> -->
                  </ion-button>
                </ion-col>
              </ion-row>

              <!-- <a class="as-text element-selector height-auto text-transform-none"
                href="mailto:office@leplace.online">Email</a> -->

            </div>

          </div>

          <!-- <div fxFlex></div>

          <div>
            <ion-grid class="full-w padding-bottom-xs">
              <ion-row>
                <ion-col size="12">
                  <ion-button class="action-button-fit button-color-primary-70" (click)="openWebpage()" icon-end>
                    <span class="button-font-size-s">www.leplace.online</span>
                    <icon-wrapper slot="start" icon="globe" class="slot-icon-padding">
                    </icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</ion-content>