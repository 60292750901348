import { ILocationContainer, IBackendLocation } from "../places/backend-location";
import { IGameItemIAP } from "../items/game-item";
import { IEditorDraft } from './editor';
import { IStoryRatingResponseContent } from '../nav-params/story';
import { IDBModelCity } from "../world/world";
import { ICurrency } from "../business/payment";


export interface IStoriesPageResponse {
    stories: IStory[];
    page: number;
    pages: number;
}

export interface IStoriesPageRequest {
    lat: number,
    lng: number,
    categoryCode: number,
    providerId: number,
    /** for local stories */
    cityId: number,
    min?: boolean,
    name?: string,
    page?: number,
    releaseFilter?: number[],
    orderBy?: number,
    sort?: number
}

export interface IMasterLockResponse {
    message: string;
    unlocked: boolean;
    /**
     * response code
     * 0 - unlocked
     * 1 - locked/level
     * 2 - locked/coins
     */
    code: number;
    aux?: {
        reload: boolean,
        alert: boolean
    };
}

export enum EStoryUnlockedCode {
    unlocked = 0,
    lockedLevel = 1,
    lockedCoins = 2
}

export enum EStoryMode {
    linear = 1,
    mp = 2,
    preload = 3
}

export interface IStoryResponse {
    story: IStory;
    locked: boolean;
    masterLock: IMasterLockResponse;
    message: string;
}

export interface IMapStyle {
    id: number;
    code: number;
    name: string;
    version: number;
    config?: any;
    lineColor?: string;
}

export interface IStory {
    id: number;
    name: string;
    shortName?: string;
    description: string;
    finishedDescription: string;
    listingDescription?: string;

    locs: ILocationContainer[];
    /**
     * db locations
     */
    locations: IBackendLocation[];
    dynamic: number;
    // favorited: boolean;
    // active: boolean;
    islocal: boolean;
    /**
     * dynamic, from server
     */
    locked: boolean;
    categoryCode: number;
    categoryName: string;
    timestamp: string;
    lat: number;
    lng: number;
    level: number;
    regionType: string;
    appVersionMin: number;
    /**
     * dynamic, from server
     */
    rating: number;

    ratingContext?: IStoryRatingResponseContent;
    /**
     * dynamic, from server
     */
    ratingCount: number;
    providerId: number;
    primaryProvider?: IBackendLocation;
    mapStyleCode: number;
    mapStyle: IMapStyle;
    photoUrl: string;
    photoUrlSmall: string;
    priceCoins: number;
    providers: IStoryProvider[];

    /**
     * the standard coin reward cap for the story
     */
    rewardCoins: number;
    rewardXp: number;
    rewardXpUnlock: number;

    itemIapCode: number;
    itemIapCodePromo?: number;
    itemIap?: IGameItemIAP;
    itemIapPromo?: IGameItemIAP;
    storyPhotos?: IStoryPhoto[];

    devOnly: number;
    enabled: number;
    droneOnly: number;
    enableAr: number;
    showBuildings?: number;
    showIndoor?: number;
    teams: number;
    /**
     * dynamic, from server
     */
    premium: boolean;
    storyUnlocks: IStoryUnlock[],
    storyProducts?: IStoryProduct[],
    qrUnlock: number;
    partnerUnlock?: number;
    // check if story is linear or mp
    mode: number;
    // check custom map stories (private treasure map)
    customMap: number;
    // search by name
    found?: boolean;
    // IAP loading
    loading?: boolean;
    isDraft?: boolean;
    draft?: IEditorDraft;
    city?: IDBModelCity;
    currency?: ICurrency;
    defaultPrice?: string;
    promoPrice?: string;
}

export interface IStoryPhoto {
    name: string;
    description: string;
    photoUrl: string;
}

export interface IStoryUnlock {
    id?: number,
    storyId?: number,
    unlockCode?: string,
    enabled?: number,
    scope?: number,
    type?: number,
    fixed?: number,
    timestampValidated?: string;
}

export interface IStoryProduct {
    id?: number,
    storyId?: number,
    providerId?: number,
    providerOtaId?: number,
    productId?: string,
    enabled?: number,
    link?: string,
    providerOta?: IBookingProvider
}

export interface IBookingProvider {
    id?: number,
    type?: number,
    code?: number,
    name?: string,
    description?: string,
    url?: string,
    photoUrl?: string,
    directBooking?: number
}

export interface IStoryLocation {
    id: number;
    storyId: number;
    locationId: number;
    treasureId: number;
    activityCode: number;
    name: string;
    description: string;
    shortDescription: string;
    finishedDescription: string;
    photoUrl: string;
    level: number
}

export enum EHiddenMarkerModes {
    show = 0,
    index = 1,
    circle = 2,
    noFrame = 3
}

export interface IUserStory {
    id: number;
    userId: number;
    storyId: number;
    done: number;
}

export interface IStoryProvider {
    id: number;
    storyId: number;
    locationId: number;
    location: IBackendLocation;
}

export interface ILocalStories {
    stories: IStory[];
    build: number;
}

export interface IStoryExtended {
    story: IStory;
    storyProgress: number;
    hide: boolean;
    show: boolean;
    photoUrl: string;
    color: string;
    canvasUrl?: string;
    canvasLoaded?: boolean;
    levels?: boolean[];
    large: boolean;
    modeIcon: string;
    modeText: string;
}

export interface IStoryExtendedGroup {
    hide: boolean;
    show: boolean;
    categoryCode: number;
    categoryName: string;
    stories: IStoryExtended[];
}


export enum EStoryListMode {
    draft = 1,
    published = 2
}

export interface IUpdateStoryTreasureItemReport {
    id: number,
    code: number,
    name: string,
    count: number
}

export interface ICustomMapItemReport {
    totalCount: number;
    items: IUpdateStoryTreasureItemReport[];
}
