

export class ApiDef {
    // public static mainServerURL = "http://localhost:8081";
    public static mainServerURL = "https://leplace.leplace-api.com";
 
    // lpconnect
    public static googleMapsApiKeyJS: string = "AIzaSyCzZiHKKXfVh-m0ou01PO5R1rR_TT4U4uY"; // lptown actually
    // lpconnect
    public static googleWebClientId: string = "930150187502-u8ho4fbp8p6bjv9veffd28lvslqa4kv3.apps.googleusercontent.com";

    // from onesignal, for lpconnect
    public static oneSignalAppId: string = "3e80c306-30f2-4d64-b0eb-dc087be7aa06";
    // from firebase, for lpconnect
    public static pushSenderId: string = "1063694113220";

    // deprecated
    public static hereMapsAppId: string = "Nhml32bk8NRCayekb4Sg";
    public static hereMapsAppCode: string = "jMT2tRQNToBlxJHqFyuk0w";

    public static apiKeysLoaded: boolean = false;

    static loadRemoteConfig(apiKeys: IApiKey[]) {
        for (let apik of apiKeys) {
            switch (apik.apiSpecCode) {
                case EApiSpec.googleWebClientId:
                    ApiDef.googleWebClientId = apik.value;
                    break;
                case EApiSpec.oneSignalAppId:
                    ApiDef.oneSignalAppId = apik.value;
                    break;
                case EApiSpec.firebasePushSenderId:
                    ApiDef.pushSenderId = apik.value;
                    break;
                case EApiSpec.googleMapsApiKeyJS:
                    ApiDef.googleMapsApiKeyJS = apik.value;
                    break;
            }
        }
    }
}


export enum EApiSpec {
    // googleplus login verification
    googleWebClientId = 1,
    // subscribe to push notifications
    oneSignalAppId = 2,
    // push notification sender
    firebasePushSenderId = 3,
    // google maps js key
    googleMapsApiKeyJS = 4
}

export interface IApiKey {
    apiSpecCode: number,
    value: string
}

export enum EAppId {
    world = 3,
    town = 2,
    connect = 4
}




