<modal-header-container [vs]="vs">
    <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
        (rightClick)="dismiss(null)" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
    [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
    <div [class]="wrapperClass" fxLayout="column">

        <div [class]="innerClass" fxLayout="column">

            <ion-row *ngIf="description && iconDescription" class="center-items">
                <ion-col size="2">
                    <img [src]="iconDescription" class="img-xxs" />
                </ion-col>
                <ion-col size="10">
                    <div linkInterceptor [innerHTML]="description"
                        class="info-text-m description-frame text-color-primary"></div>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="description && !iconDescription" class="center-items">
                <ion-col size="12">
                    <div linkInterceptor [innerHTML]="description"
                        class="info-text-m description-frame text-color-primary"></div>
                </ion-col>
            </ion-row>

            <div *ngIf="unlockCodes">
                <ion-row *ngFor="let uc of unlockCodes">
                    <ion-col size="12">
                        <div class="border-frame">
                            <qr-code *ngIf="uc.isQR" index="0" [code]="uc.code" [interact]="false">
                            </qr-code>
                            <edit-code *ngIf="!uc.isQR" [disabled]="true" [(prop)]="uc.code" placeholder=""
                                [numeric]="false" [dim]="unlockCodeShortLength" [autocaps]="true">
                                <inner-content>Enter unlock code</inner-content>
                            </edit-code>
                        </div>
                    </ion-col>
                </ion-row>
            </div>

            <div *ngIf="photoUrl" class="padding-s">
                <responsive-img [photoUrl]="photoUrl" class="border-radius"></responsive-img>
            </div>

            <ion-row *ngIf="descriptionFooter" class="center-items">
                <ion-col size="2">
                    <img [src]="iconFooter" class="img-xxs" />
                </ion-col>
                <ion-col size="10">
                    <div linkInterceptor [innerHTML]="descriptionFooter"
                        class="info-text-m description-frame text-color-primary"></div>
                </ion-col>
            </ion-row>

        </div>

        <div fxFlex>
        </div>

        <div class="stick-bottom" [ngClass]="buttonBar">
            <ion-grid class="full-w">
                <ion-row>
                    <ion-col size="12">
                        <ion-button class="action-button-fill button-color-alternate" (click)="ok()" icon-end>
                            <span class="button-font-size-s">{{continueBtnText}}</span>
                            <icon-wrapper slot="start" [icon]="appIconsStandard.clipboard" [custom]="false"
                                class="slot-icon-padding">
                            </icon-wrapper>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
</modal-container>