
import { Injectable } from '@angular/core';
import { UiExtensionService } from '../general/ui/ui-extension';
import { FileManagerService } from './file';
import { GenericDataService } from '../general/data/generic';
import { EPhotos } from '../../classes/def/app/icons';
import { IBase64Ext, IImageSize } from 'src/app/classes/def/media/photo';
import { AppConstants } from 'src/app/classes/app/constants';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';

export interface IResizeImageResult {
    content: string | Blob,
    warn: boolean,
    message: string,
    detail: string
}

/**
 * the interface between the top providers (callers) and the low level providers (save to file, request apis)
 * handles the direct media apis and interfaces (camera, sound recording, media formatting etc)
 */
@Injectable({
    providedIn: 'root'
})
export class MediaUtilsService {

    targetWidth: number = 1024;
    quality: number = 60;
    maxDataSize: number = 1024;

    contentTypes: string[] = ['image/jpg', 'image/jpeg', 'image/png'];
    private win: any = window;

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public generic: GenericDataService,
        public uiext: UiExtensionService,
        public fileManager: FileManagerService,
        public settingsProvider: SettingsManagerService

    ) {
        console.log("media utils service created");

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    initConfig() {
        if (AppConstants.gameConfig.photoTargetWidth != null) {
            this.targetWidth = AppConstants.gameConfig.photoTargetWidth;
        }
        if (AppConstants.gameConfig.photoTargetQuality != null) {
            this.quality = AppConstants.gameConfig.photoTargetQuality;
        }
        if (AppConstants.gameConfig.maxPhotoDataSize != null) {
            this.maxDataSize = AppConstants.gameConfig.maxPhotoDataSize;
        }
    }

    getImgSrc(src: string) {
        if (src) {
            return this.win.Ionic.WebView.convertFileSrc(src);
        }
        return src
    }

    uploadPhotoViaBrowser() {
        return new Promise<string>((resolve, reject) => {
            this.fileManager.uploadFileViaBrowser(".png, .jpg", true, null).then((res: string) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    /**
     * resize base64 image
     * @param imageData 
     */
    resizeImage(imageData: string, format: string): Promise<IResizeImageResult> {
        let promise: Promise<IResizeImageResult> = new Promise((resolve, reject) => {
            this.resizeImageCore(imageData, this.targetWidth, this.targetWidth, format).then((res: IResizeImageResult) => {
                console.log("image resized (blob)");
                this.blobToB64(res.content as Blob).then((imageB64: string) => {
                    console.log("image resized (b64)");
                    res.content = imageB64;
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    checkDataSize(imageData: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let b64Size: number = imageData.length / 1024;
            let imgSize: number = Math.floor(b64Size / 1.37);
            if (imgSize < this.maxDataSize) {
                resolve(true);
            } else {
                reject(new Error("<p>Image too large: " + imgSize + " KB</p><p>Allowed max: " + this.maxDataSize + " KB<p><p>You may try to resize the image or use .jpg instead of .png format</p>"));
            }
        });
        return promise;
    }

    /**
     * Convert a base64 string in a Blob according to the data and contentType.
     * 
     * @param b64Data {String} Pure base64 string without contentType
     * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
     * @param sliceSize {Int} SliceSize to process the byteCharacters
     * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @return Blob
     */
    b64toBlob(b64Data: string, contentType: string, sliceSize: number) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    /**
     * Convert a Blob to base64 string
     * @param blob 
     */
    blobToB64(blob: Blob) {
        let promise = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                let dataUrl: string = reader.result as string;
                // let base64 = dataUrl.split(',')[1];
                let base64 = dataUrl;
                resolve(base64);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(blob);
        });
        return promise;
    }


    /**
     * remove header (content type) and keep content only
     * @param base64Data 
     */
    formatBase64PhotoContent(base64Data: string): IBase64Ext {
        let contentType = this.contentTypes[0];
        for (let i = 0; i < this.contentTypes.length; i++) {
            if (base64Data.indexOf(this.contentTypes[i]) !== -1) {
                contentType = this.contentTypes[i];
                break;
            }
        }

        let base64DataContent: string = base64Data.replace("data:" + contentType + ";base64,", "");
        let res: IBase64Ext = {
            content: base64DataContent,
            contentType
        };
        return res;
    }


    /**
     * get logo resized to the container with relative width spec
     * @param containerWidth 
     */
    getLogoResized(containerWidth: number, relativeSize: number = 0.2) {
        let promise = new Promise((resolve, reject) => {
            fetch(EPhotos.logo).then((res) => {
                res.blob().then((blob: Blob) => {
                    let width = containerWidth * relativeSize;
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = (event: any) => {
                        const img = new Image();
                        img.src = event.target.result;
                        img.onload = () => {
                            const elem = document.createElement('canvas');
                            const scaleFactor = width / img.width;
                            elem.width = width;
                            elem.height = img.height * scaleFactor;
                            const ctx = elem.getContext('2d');
                            // img.width and img.height will contain the original dimensions
                            ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
                            ctx.canvas.toBlob((blob) => {
                                // const file = new File([blob], fileName, {
                                //     type: 'image/jpeg',
                                //     lastModified: Date.now()
                                // });
                                resolve(blob);
                            }, 'image/png', this.quality);
                        };
                        img.onerror = (err) => {
                            reject(err);
                        };
                    };
                    reader.onerror = (err) => {
                        reject(err);
                    };
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * resize image
     * downscale only
     * @param imageB64 
     * @param targetWidth 
     * @param format 
     */
    private resizeImageCore(imageB64: string, targetWidth: number, maxHeight: number, format: string): Promise<IResizeImageResult> {
        let resizeImageResult: IResizeImageResult = {
            content: null,
            warn: false,
            message: null,
            detail: null
        };
        let promise: Promise<IResizeImageResult> = new Promise((resolve, reject) => {
            if (!format) {
                // extract from b64
                // data:image/png,
                let b64test: string = imageB64.slice(imageB64.indexOf("data:"), imageB64.indexOf(","));
                console.log(b64test);
                if (
                    (b64test.indexOf("jpeg") !== -1) ||
                    (b64test.indexOf("jpg") !== -1)
                ) {
                    format = "image/jpeg";
                } else if (b64test.indexOf("png") !== -1) {
                    format = "image/png";
                } else {
                    console.log("unrecognized format");
                    format = "image/jpeg";
                }
                console.log("format detected: ", format);
            }
            fetch(imageB64).then((res) => {
                res.blob().then((blob: Blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = (event: any) => {
                        const img = new Image();
                        img.src = event.target.result;
                        img.onload = () => {
                            const elem = document.createElement('canvas');
                            let scaleFactor: number = 0;

                            let newWidth: number = 0;
                            let newHeight: number = 0;

                            resizeImageResult.detail = "<p>image might look slightly distorted</p><p>recommended size/width: " + targetWidth + " px</p>";

                            if (img.width < targetWidth) {
                                // keep original / don't downscale
                                scaleFactor = 1;
                                newWidth = img.width;
                                newHeight = img.height;
                            } else {
                                // downscale
                                scaleFactor = targetWidth / img.width;
                                newWidth = targetWidth;
                                newHeight = Math.floor(img.height * scaleFactor);
                                if (newHeight !== (img.height * scaleFactor)) {
                                    resizeImageResult.warn = true;
                                    resizeImageResult.message = "image resize mismatch";
                                }
                            }

                            console.log("resize width scale factor: " + scaleFactor + " (" + newWidth + "x" + newHeight + ")");

                            if (newHeight > maxHeight) {
                                scaleFactor = maxHeight / newHeight;
                                newHeight = maxHeight;
                                newWidth = Math.floor(newWidth * scaleFactor);
                                if (newWidth !== (newWidth * scaleFactor)) {
                                    resizeImageResult.warn = true;
                                    resizeImageResult.message = "image resize mismatch";
                                }
                                console.log("resize height scale factor: " + scaleFactor + " (" + newWidth + "x" + newHeight + ")");
                            }

                            elem.width = newWidth;
                            elem.height = newHeight;

                            if (resizeImageResult.warn) {
                                this.uiext.showToastNoAction(resizeImageResult.message, false);
                            }

                            const ctx = elem.getContext('2d');
                            // img.width and img.height will contain the original dimensions
                            ctx.drawImage(img, 0, 0, newWidth, newHeight);
                            ctx.canvas.toBlob((blob) => {
                                resizeImageResult.content = blob;
                                resolve(resizeImageResult);
                            }, format, this.quality);
                        };
                        img.onerror = (err) => {
                            reject(err);
                        };
                    };
                    reader.onerror = (err) => {
                        reject(err);
                    };
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get image size from blob
     * @param blob 
     */
    getImageSizeFromBlob(blob: Blob): Promise<IImageSize> {
        let promise: Promise<IImageSize> = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = (event: any) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    let result: IImageSize = {
                        width: img.width,
                        height: img.height
                    };
                    resolve(result);
                };
                img.onerror = (err) => {
                    reject(err);
                };
            };
            reader.onerror = (err) => {
                reject(err);
            };
        });
        return promise;
    }

    formatImg(photoUrl: string) {
        return this.generic.genericPostStandardWData("/media/format/format-img", {
            photoUrl: photoUrl
        });
    }
}







